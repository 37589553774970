<template>
  <div class="chart" ref="chart" style="width:calc(100vw - 300px);height:100%"></div>
</template>

<script>
import * as echarts from 'echarts'
import { cloneDeep } from 'lodash'
export default {
  name: 'LineChart',
  props: {
    yAxisName: {
      type: String,
      default: '',
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      chart: null,
      defaultOptions: {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          x: 'left',
          y: 'top',
          data: [],
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: 20,
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: { interval: 0 },
        },
        yAxis: {
          type: 'value',
          max: 100,
          minInterval: 1, // 设置最小间隔为1
          axisLabel: {
            formatter: '{value}', // 显示整数
            interval: 'auto' // 自动隐藏非整数刻度
          }
        },
        dataZoom: [
          {
            type: 'slider', // 使用滑动条形式的 dataZoom 组件
            startValue: 0,
            endValue: 10,
            zoomLock: true,
            zoomOnMouseWheel: false,
            brushSelect: false,
            showDetail: false,
            showDataShadow: false,
          },
        ],
      },
    }
  },
  mounted () { },

  methods: {
    initChart (xAxisData = [], yAxisData = [], max = 100) {
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.chart)
      }
      if (max === 0) {
        this.defaultOptions.yAxis.max = 100
      } else {
        this.defaultOptions.yAxis.max = max
      }

      this.defaultOptions.legend.data = yAxisData.map((item) => item.name)
      this.defaultOptions.xAxis.data = xAxisData
      this.defaultOptions.series = yAxisData
      if (xAxisData.length > 7) {
        this.defaultOptions.dataZoom[0].show = true
      } else {
        this.defaultOptions.dataZoom[0].show = false
      }
      this.chart.setOption(this.defaultOptions)
    },
    changeLegend (name, type = 'legendToggleSelect', selected) {
      if (this.chart) {
        this.chart.dispatchAction({
          type: type,
          name: name,
        })
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
