<template>
  <a-card hoverable class="data-card">
    <div class="title">
      <div class="title-left">
        <h3>{{ title }}</h3>
        <span v-if="dateText">(统计时间：{{ dateText }})</span>
      </div>
      <div class="title-right">
        <a-radio-group v-model="dateType" @change="changeDateType">
          <a-radio-button v-for="item in selectDateList" :key="item.value" :value="item.value">{{ item.name }}</a-radio-button>
        </a-radio-group>
        <a-range-picker v-model="selectDate" style="margin-left: 20px" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" @change="chanegDate" />
      </div>
    </div>
    <div class="content">
      <slot />
    </div>
  </a-card>
</template>

<script>
import moment from 'moment'
const dataFormat = 'YYYY-MM-DD'
const selectDateList = [
  { value: 1, name: '昨天', date: moment().subtract(1, 'days').format(dataFormat) },
  { value: 2, name: '近7天', date: moment().subtract(6, 'days').format(dataFormat) },
  { value: 3, name: '近30天', date: moment().subtract(29, 'days').format(dataFormat) },
]

const defaultDate = [moment().subtract(1, 'days').format(dataFormat), moment().subtract(1, 'days').format(dataFormat)]
export default {
  props: {
    title: { type: String },
  },
  data() {
    return {
      dateType: 1,
      selectDateList,
      selectDate: defaultDate,
      form: {
        start_date: defaultDate[0],
        end_date: defaultDate[1],
      },
    }
  },
  computed: {
    dateText: function () {
      if (this.selectDate && this.selectDate.length === 2) {
        return this.selectDate[0] + '~' + this.selectDate[1]
      } else {
        return ''
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit('changeDate', { checkIndex: 1, periodStart: this.form.start_date, periodEnd: this.form.end_date })
    })
  },
  methods: {
    changeDateType(e) {
      if (e.target.value === 1) {
        this.selectDate = [moment().subtract(1, 'days').format(dataFormat), moment().subtract(1, 'days').format(dataFormat)]
      } else if (e.target.value === 2) {
        this.selectDate = [moment().subtract(6, 'days').format(dataFormat), moment().format(dataFormat)]
      } else {
        this.selectDate = [moment().subtract(29, 'days').format(dataFormat), moment().format(dataFormat)]
      }
      this.form.start_date = this.selectDate[0]
      this.form.end_date = this.selectDate[1]
      this.$emit('changeDate', { checkIndex: this.dateType, periodStart: this.form.start_date, periodEnd: this.form.end_date })
    },
    chanegDate(e) {
      this.dateType = undefined
      if (e && e.length) {
        this.form.start_date = e[0]
        this.form.end_date = e[1]
        this.$emit('changeDate', { checkIndex: this.dateType, periodStart: this.form.start_date, periodEnd: this.form.end_date })
      } else {
        this.form.start_date = ''
        this.form.end_date = ''
        this.$message.info('请选择一个时间范围')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.data-card {
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-left {
      display: flex;
      align-items: center;
      h3 {
        font-weight: bold;
        margin: 0 20px 0 0;
      }
    }
    .titel-right {
      display: flex;
      align-items: center;
    }
  }
}
</style>
