<template>
  <div class="store-data-container">
    <data-card title="素材库转发统计" @changeDate="getSummaryTranspond">
      <div class="box">
        <div class="item" v-for="(item, index) in materialMap" :key="'material' + index">
          <div class="label">{{ item.label }}</div>
          <div class="value">{{ item.count }}</div>
        </div>
      </div>
    </data-card>
    <div class="mt20">
      <data-card title="门店数据" @changeDate="getsummaryBrand">
        <div class="box storeBox">
          <div class="item" :class="{ 'item-active': item.id === active }" v-for="(item, index) in storeMap"
            :key="'store' + index" @click="changeLegend(item)">
            <div class="label">{{ item.label }}</div>
            <div class="value">{{ item.count }}</div>
          </div>
        </div>
        <div class="storeChart mt20">
          <line-chart ref="storeChart"></line-chart>
        </div>

      </data-card>
    </div>
  </div>
</template>
<script>
import DataCard from './Datacard.vue'
import LineChart from '@/components/charts/LineChart.vue'
import StoreModel from '@/api/brandManage/store'

export default {
  components: {
    DataCard,
    LineChart,
  },
  data () {
    return {
      materialMap: [
        { label: '全部', count: 0 },
        { label: '图文转发数', count: 0 },
        { label: '文章转发数', count: 0 },
        { label: '作品转发数', count: 0 },
        { label: '视频转发数', count: 0 },
      ],
      storeMap: [
        { id: 1, label: '全部', count: 0 },
        { id: 2, label: '门店浏览次数', count: 0 },
        { id: 3, label: '门店分享次数', count: 0 },
        { id: 4, label: '点击联系次数', count: 0 },
        { id: 5, label: '得到联系方式次数', count: 0 },
      ],
      active: 1,
      chart: null,
    }
  },
  mounted () { },
  methods: {
    getSummaryTranspond (params) {
      StoreModel.summaryTranspond(params).then((res) => {
        this.materialMap = [
          { label: '全部', count: res.count || 0 },
          { label: '图文转发数', count: res.imageTranspondCount || 0 },
          { label: '文章转发数', count: res.articleTranspondCount || 0 },
          { label: '作品转发数', count: res.workTranspondCount || 0 },
          { label: '视频转发数', count: res.videoTranspondCount || 0 },
        ]
      })
    },
    getsummaryBrand (params) {
      StoreModel.summaryBrand(params).then((res) => {
        this.storeMap = [
          { id: 1, label: '全部', count: res.count || 0 },
          { id: 2, label: '门店浏览次数', count: res.browseCount || 0 },
          { id: 3, label: '门店分享次数', count: res.shareCount || 0 },
          { id: 4, label: '点击联系次数', count: res.contactCount || 0 },
          { id: 5, label: '得到联系方式次数', count: res.contactSuccessCount || 0 },
        ]
      })
      StoreModel.graph(params).then((data) => {
        let xAxisData = data.map((item) => item.timeLine)
        let yAxisData = [
          {
            name: '门店浏览次数',
            type: 'line',
            data: [],
          },
          {
            name: '门店分享次数',
            type: 'line',
            data: [],
          },
          {
            name: '点击联系次数',
            type: 'line',
            data: [],
          },
          {
            name: '得到联系方式次数',
            type: 'line',
            data: [],
          },
        ]
        data.forEach((item) => {
          yAxisData[0].data.push(item.brandSummary.browseCount)
          yAxisData[1].data.push(item.brandSummary.shareCount)
          yAxisData[2].data.push(item.brandSummary.contactCount)
          yAxisData[3].data.push(item.brandSummary.contactSuccessCount)
        })
        let a1 = Math.max.apply(null, yAxisData[0].data)
        let a2 = Math.max.apply(null, yAxisData[1].data)
        let a3 = Math.max.apply(null, yAxisData[2].data)
        let a4 = Math.max.apply(null, yAxisData[3].data)
        console.log(a1, a2, a3, a4);
        let max = Math.max.apply(null, [a1, a2, a3, a4])
        console.log(max);
        this.$nextTick(() => {
          this.$refs.storeChart.initChart(xAxisData, yAxisData, max)
        })
      })
    },

    changeLegend (item) {
      this.active = item.id
      if (item.label === '全部') {
        this.$refs.storeChart.changeLegend(item.label, 'legendAllSelect')
      } else {
        this.storeMap.forEach((T) => {
          if (T.id === this.active) {
            this.$refs.storeChart.changeLegend(T.label, 'legendSelect')
          } else {
            this.$refs.storeChart.changeLegend(T.label, 'legendUnSelect')
          }
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
$border-color: rgba(240, 240, 240, 1);

.store-data-container {
  padding: 20px;
  font-weight: 13px;

  .box {
    width: 100%;
    height: 124px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 20px;
    border: 1px solid $border-color;
    border-radius: 4px;

    .item {
      width: 100%;
      height: 100%;
      border-right: 1px solid $border-color;
      margin-right: 20px;

      .label {
        width: max-content;
        border-bottom: 1px dashed #7f7f7f;
        color: #7f7f7f;
        margin-bottom: 10px;
      }

      .value {
        color: #000;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .item:last-child {
      margin-right: 0;
      border-right: none;
    }
  }

  .storeBox {
    padding: 0;

    .item {
      padding: 20px;
      margin-right: 0px;
    }

    @mixin active {
      border-top: 3px solid rgb(22, 139, 248);
      background-color: rgba(24, 144, 255, 0.0980392156862745);
    }

    .item:hover {
      @include active;
      border-right: 2px solid #fff;
      background-color: rgba(24, 144, 255, 0.0380392156862745);

      .label {
        color: #000;
        font-weight: bold;
      }
    }

    .item-active {
      @include active;
    }
  }

  .storeChart {
    width: 100%;
    height: 600px;
    overflow: auto;
  }
}
</style>
